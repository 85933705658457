<template>
  <div>
    <!--begin::Forgot-->
    <div class="login-form">
      <!--begin::Form-->
      <form class="form" novalidate="novalidate" id="kt_login_forgot_form">
        <!--begin::Title-->
        <div class="pb-13 pt-lg-0 pt-5">
          <h3 class="font-weight-bolder text-dark font-size-h1 font-size-h1-lg">{{ $t("auth.title.forgot_title") }}?</h3>
          <p class="text-muted font-weight-bold font-size-h5">{{ $t("auth.title.forgot_subtitle") }}</p>
        </div>
        <!--end::Title-->
        <!--begin::Form group-->
        <div class="form-group">
          <b-form class="form">

            <b-form-group>
              <b-form-input
                class="form-control form-control-solid h-auto py-6 px-6 rounded-lg"
                id="email"
                name="email"
                type="email"
                v-model="$v.form.email.$model"
                :state="validateState('email')"
                aria-describedby="email-live-feedback"
                :placeholder="$t('auth.placeholder.login')"
              ></b-form-input>
              <b-form-invalid-feedback id="email-live-feedback">
                Uživatelské jméno je povinné.
              </b-form-invalid-feedback>
            </b-form-group>

          </b-form>
        </div>
        <!--end::Form group-->
        <!--begin::Form group-->
        <div class="form-group d-flex flex-wrap pb-lg-0">
          <button type="button" id="kt_login_forgot_submit"  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4" @click.prevent="resetPassword">
            {{ $t("button.restore_password") }}
          </button>
         
          <router-link :to="{ name: 'login'}" class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3">
            {{ $t("button.login") }}
          </router-link>

        </div>
        <!--end::Form group-->
      </form>
      <!--end::Form-->
    </div>
    <!--end::Forgot-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { checkErrors } from "@/core/utils/api-errors";
import ApiService from "@/core/services/api.service";

export default {
  name: "forgot",
  mixins: [validationMixin],
  data() {
    return {
      laravelVE: null,
      form: {
        email: null,
      }
    };
  },
  validations: {
    form: {
      email: {
        required
      }
    }
  },
  metaInfo () { 
      return { title: this.$t("auth.meta.forgot")} 
  },
  methods: {
   validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetPassword() {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
            return;
        }
        ApiService.apiPost("/auth/reset-password", { 
        'email': this.$v.form.email.$model,
        })
        .catch(error => {
          this.laravelVE = checkErrors(error);
          console.log(this.laravelVE);
        })
    },
    resetForm() {
        console.log('Reseting the form')
        var self = this; //you need this because *this* will refer to Object.keys below`

        //Iterate through each object field, key is name of the object field`
        Object.keys(this.form).forEach(function(key) {
          self.form[key] = '';
        });
    }
  }
};
</script>
